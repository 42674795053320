<template>
  <div class="mt-4">
    <div v-for="error in props.errors" :key="error.code">
      <div>{{ error.code }}</div>
      <div>{{ error.description }}</div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { Error as BringError } from "@/goods-out/bring-integration/BringShippingGuideResponse";

const props = defineProps<{
  errors: BringError[];
}>();
</script>

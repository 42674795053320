<template>
  <div class="c-toolbar-wrapper">
    <div class="c-toolbar">
      <div class="c-header-icon-container">
        <PrimeButton
          class="c-circular-button mr-3"
          @click="emit('openDetailedOverview')"
          data-testid="btn-detailed-overview"
        >
          <i class="pi pi-search-plus c-default-button c-circular-icon"></i>
          <span class="px-3">{{ t("inventory.header.detailed") }}</span>
        </PrimeButton>

        <PrimeButton
          class="c-circular-button mr-3"
          @click="emit('editInventoryProductQuantity')"
          data-testid="btn-change-inventory"
        >
          <i class="pi pi-sliders-v c-default-button c-circular-icon"></i>
          <span class="px-3">{{ t("inventory.header.inventory") }}</span>
        </PrimeButton>

        <PrimeButton
          class="c-circular-button mr-3"
          @click="emit('editInventoryProductCost')"
          data-testid="btn-change-cost"
        >
          <i class="pi pi-dollar c-default-button c-circular-icon"></i>
          <span class="px-3">{{ t("inventory.header.cost") }}</span>
        </PrimeButton>

        <PrimeButton
          class="c-circular-button mr-3 c-split-button"
          data-testid="btn-inventory-export"
          @click="visibleExportDialog = true"
        >
          <i class="pi pi-file c-default-button c-circular-icon p-2"></i>
          <span class="px-3 m-auto">{{ t("inventory.export") }}</span>
        </PrimeButton>
        <PrimeButton
          class="c-circular-button"
          data-testid="btn-inventory-changes-log"
          @click="emit('inventoryChangesLogBtnClicked')"
        >
          <i class="pi pi-file c-default-button c-circular-icon p-2"></i>
          <span class="px-3 m-auto">{{ t("inventory.change-log.inventory-changes-log") }}</span>
        </PrimeButton>
      </div>
      <div class="c-header-filter-container c-header-icon-container">
        <ActiveFilters v-model:filters="filtersComputed" :warehouses="warehouses" :manufacturers="manufacturers" />
        <PrimeButton class="c-circular-button" @click="emit('onToggleFilterSidebar')">
          <i class="pi pi-filter c-default-button c-circular-icon"></i>
          <span class="px-3">{{
            showSidebar ? t("inventory.header.hide-filterbar") : t("inventory.header.show-filterbar")
          }}</span>
        </PrimeButton>
      </div>
    </div>
  </div>

  <ExportInventoryProductsDialog v-model:visibleDialog="visibleExportDialog" :warehouses="warehouses" />
</template>

<script setup lang="ts">
import { ProductSearchFilters } from "@/repositories/search/model/product/ProductSearchFilters";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import ActiveFilters from "../components/ActiveFilters.vue";
import { Manufacturer } from "@/repositories/manufacturer/model/Manufacturer";
import { Warehouse } from "@/repositories/warehouse/model/Warehouse";
import ExportInventoryProductsDialog from "../components/ExportInventoryProductsDialog.vue";

const props = defineProps<{
  showSidebar: boolean;
  filters: ProductSearchFilters;
  manufacturers: Manufacturer[];
  warehouses: Warehouse[];
}>();

const emit = defineEmits<{
  (e: "openDetailedOverview"): void;
  (e: "editInventoryProductQuantity"): void;
  (e: "editInventoryProductCost"): void;
  (e: "onToggleFilterSidebar"): void;
  (e: "inventoryChangesLogBtnClicked"): void;
  (e: "update:filters", value: ProductSearchFilters): void;
}>();

const { t } = useI18n();

const visibleExportDialog = ref<boolean>(false);

const filtersComputed = computed({
  get: () => {
    return props.filters;
  },
  set: (value: ProductSearchFilters) => {
    emit("update:filters", value);
  },
});
</script>

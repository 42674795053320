<template>
  <label for="checkout-freight-price">
    {{ t("checkout.shippingPrice") }}
  </label>

  <div class="p-inputgroup">
    <InputNumber
      id="checkout-freight-price"
      mode="decimal"
      :allow-empty="false"
      :minFractionDigits="2"
      :maxFractionDigits="2"
      v-model="shippingPrice"
      data-testid="checkout-freight-price"
      class="w-full"
      :min="0"
      suffix=" kr"
      @focus="selectAllOnFocus"
      @input="priceOverriden = true"
      :disabled="props.agreedPrice"
    />
    <i
      v-if="priceOverriden || props.agreedPrice"
      class="pi pi-lock ml-2"
      @click="priceOverriden = false"
      data-testid="shipping-price-locked"
    />
    <i v-else class="pi pi-lock-open ml-2" @click="priceOverriden = true" data-testid="shipping-price-unlocked" />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const props = defineProps<{
  agreedPrice: boolean;
}>();
const shippingPrice = defineModel<number>("shippingPrice", {
  required: true,
});
const priceOverriden = defineModel<boolean>("priceOverriden", {
  required: true,
});
const selectAllOnFocus = (event: Event) => {
  (event.target as HTMLInputElement).select();
};
</script>

<template>
  <label for="warehouse-currency">{{ t("warehouse.currency") }}</label>
  <Dropdown
    id="warehouse-currency"
    data-testid="warehouse-currency"
    :options="currencies"
    optionLabel="name"
    optionValue="name"
    class="inputfield w-full"
    :class="{ 'p-invalid': validate.currencyIso.$error }"
    v-model="currencyIso"
    :placeholder="t('placeholder.select', { property: t('warehouse.currency').toLowerCase() })"
    @show="isActive = true"
    @hide="isActive = false"
    @keydown.esc="isActive === true ? $event.stopPropagation() : ''"
  />
  <small class="p-error" v-if="validate.currencyIso.$error" data-testid="warehouse-currency-error">
    {{ validate.currencyIso.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import { Currency } from "@/repositories/currency/model/Currency";
import { onMounted, ref } from "vue";
import { currencyRepository } from "@/repositories/currency/CurrencyRepository";

const { t } = useI18n();
const currencies = ref<Currency[]>([]);
const loading = ref();
const isActive = ref(false);

const currencyIso = defineModel("currencyIso", {
  required: true,
});

const rules = {
  currencyIso: {
    required,
  },
};

const loadCurrencies = async () => {
  loading.value = true;
  try {
    currencies.value = await currencyRepository.getAll();
  } finally {
    loading.value = false;
  }
};

onMounted(loadCurrencies);

const validate = useVuelidate(rules, { currencyIso });
</script>

<template>
  <label for="warehouse-state">{{ t("warehouse.state") }}</label>
  <Dropdown
    id="warehouse-state"
    v-model="selectedState"
    class="inputfield w-full"
    :options="Object.keys(WarehouseStates)"
    data-testid="warehouse-state"
    @show="isActive = true"
    @hide="isActive = false"
    @keydown.esc="isActive === true ? $event.stopPropagation() : ''"
  />
  <small class="p-error" v-if="validate.warehouseState.$error" data-testid="warehouse-state-error">
    {{ validate.warehouseState.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import { WarehouseStates } from "@/repositories/warehouse/model/WarehouseStates";

const { t } = useI18n();
const isActive = ref(false);

const props = defineProps<{
  warehouseState: WarehouseStates;
}>();

const selectedState = computed<WarehouseStates>({
  get: (): WarehouseStates => {
    return props.warehouseState;
  },
  set: (value: WarehouseStates) => {
    emit("update:warehouseState", value);
  },
});

const emit = defineEmits<{ (e: "update:warehouseState", value: WarehouseStates): void }>();

const rules = {
  warehouseState: {
    required,
  },
};

const validate = useVuelidate(rules, props);
</script>

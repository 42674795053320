<template>
  <div class="c-toolbar-wrapper">
    <div class="c-toolbar">
      <div class="c-header-icon-container">
        <PrimeButton
          @click="emit('createPickingLists')"
          data-testid="btn-create-picking-lists"
          class="c-circular-button mr-3"
        >
          <i class="pi pi-list c-success-button c-circular-icon"></i>
          <span class="px-3">{{ t("order-ready-for-picking.context-menu.create-picking-lists") }}</span>
        </PrimeButton>
        <PrimeButton @click="emit('cancel')" data-testid="btn-cancel" class="c-circular-button mr-3">
          <i class="pi pi-times c-warning-button c-circular-icon"></i>
          <span class="px-3">{{ t("order-ready-for-picking.context-menu.cancel") }}</span>
        </PrimeButton>
        <PrimeButton @click="emit('selectAll')" data-testid="btn-select-all" class="c-circular-button mr-3">
          <i class="pi pi-check c-default-button c-circular-icon"></i>
          <span class="px-3">{{ t("order-ready-for-picking.context-menu.select-all") }}</span>
        </PrimeButton>
      </div>
      <div class="c-header-filter-container c-header-icon-container">
        <FilterOverview
          v-model:filters="filtersComputed"
          :customer-groups="customerGroups"
          :freightMethods="freightMethods"
        />
        <PrimeButton class="c-circular-button" @click="emit('onToggleFilterSidebar')">
          <i class="pi pi-filter c-default-button c-circular-icon"></i>
          <span class="px-3">{{
            showSidebar ? t("common.filters.hide-filter") : t("common.filters.show-filter")
          }}</span>
        </PrimeButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { computed } from "vue";
import { SearchFilters } from "@/repositories/search/model/order/SearchFilters";
import FilterOverview from "../picking-list/FilterOverview.vue";
import { CustomerGroup } from "@/repositories/customer-group/model/CustomerGroup";
import { FreightMethod } from "@/repositories/freight-method/model/FreightMethod";
const { t } = useI18n();

const props = defineProps<{
  showSidebar: boolean;
  filters: SearchFilters;
  customerGroups: CustomerGroup[];
  freightMethods: FreightMethod[];
}>();

const emit = defineEmits<{
  (e: "createPickingLists"): void;
  (e: "selectAll"): void;
  (e: "cancel"): void;
  (e: "onToggleFilterSidebar"): void;
  (e: "update:filters", value: SearchFilters): void;
}>();

const filtersComputed = computed({
  get() {
    return props.filters;
  },
  set(value: SearchFilters) {
    emit("update:filters", value);
  },
});
</script>

export const PickingLinesColumn = {
  PositionNumber: "positionNumber",
  ProductNumber: "productNumber",
  SupplierProductNumber: "supplierProductNumber",
  Gtin: "gtin",
  ProductName: "productName",
  QuantityForPicking: "quantityForPicking",
  QuantityPicked: "quantityPicked",
  QuantityRemaining: "quantityRemaining",
} as const;

<template>
  <CumulusRightSidebar v-model:showSidebar="showSidebarComputed" :enableDragover="false" :sidebarWidth="260">
    <template v-slot:header>
      <div class="c-filter-header-container">
        <i class="pi pi-sliders-h"></i>
        <h1>{{ t("common.filters.filter") }}</h1>
      </div>
    </template>
    <template v-slot:body>
      <h4 class="text-center">{{ t("inventory.warehouse") }}</h4>
      <Dropdown
        id="inventory-warehouses"
        v-model="selectedWarehouseIdComputed"
        :options="warehouses"
        optionLabel="name"
        optionValue="id"
        :placeholder="t('placeholder.select', { property: t('warehouse.header').toLowerCase() })"
        class="w-full"
        data-testid="inventory-filter-warehouse"
        :loading="loading"
      />

      <hr class="c-seperator-line" />
      <h4 class="text-center">{{ t("inventory.search-filters.manufacturer") }}</h4>
      <MultiSelect
        id="inventory-manufacturers"
        data-testid="inventory-manufacturers"
        v-model="selectedManufacturerIdsComputed"
        :placeholder="t('placeholder.select', { property: t('inventory.search-filters.manufacturer').toLowerCase() })"
        optionLabel="name"
        optionValue="id"
        :options="manufacturers"
        display="chip"
        class="w-full field"
        :filter="true"
        :loading="loading"
      />

      <hr class="c-seperator-line" />
      <h4 class="text-center">{{ t("inventory.search-filters.status") }}</h4>
      <div
        v-for="(status, index) in allStatuses"
        :key="index"
        class="flex justify-content-between relative align-items-center p-2 border-round-md"
        :class="{ 'c-list-row-bg mt-1': index % 2 === 0, 'c-list-alternate-row-bg mt-1': index % 2 === 1 }"
      >
        <label :for="'product-status-' + status.toLowerCase()">{{
          t(`inventory.product-status.${status.toLowerCase()}`)
        }}</label>
        <Checkbox
          v-model="statusesComputed"
          :value="status"
          :inputId="'product-status-' + status.toLowerCase()"
          :data-testid="'product-status-' + status.toLowerCase()"
        />
      </div>
    </template>
  </CumulusRightSidebar>
</template>

<script setup lang="ts">
import { Manufacturer } from "@/repositories/manufacturer/model/Manufacturer";
import { ProductSearchFilters } from "@/repositories/search/model/product/ProductSearchFilters";
import { ProductStatus } from "@/repositories/search/model/product/ProductStatus";
import { Warehouse } from "@/repositories/warehouse/model/Warehouse";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { CumulusRightSidebar } from "@cumulus/components";

const { t } = useI18n();

const allStatuses = ref<ProductStatus[]>(Object.values(ProductStatus));

const props = defineProps<{
  showSidebar: boolean;
  filters: ProductSearchFilters;
  warehouses: Warehouse[];
  manufacturers: Manufacturer[];
  loading: boolean;
}>();

const emit = defineEmits<{
  (event: "update:showSidebar", value: boolean): void;
  (event: "update:filters", value: ProductSearchFilters): void;
  (event: "update:selectedWarehouseId", value: string): void;
}>();

const showSidebarComputed = computed<boolean>({
  get: () => props.showSidebar,
  set: (value) => emit("update:showSidebar", value),
});

const selectedWarehouseIdComputed = computed<string>({
  get: () => props.filters.warehouses[0],
  set: (value) => {
    const filters = { ...props.filters, warehouses: [value] };
    emit("update:filters", filters);
  },
});

const statusesComputed = computed<ProductStatus[]>({
  get: () => props.filters.productStatuses,
  set: (value) => {
    const filters = { ...props.filters, productStatuses: value };
    emit("update:filters", filters);
  },
});

const selectedManufacturerIdsComputed = computed<string[]>({
  get: () => props.filters.manufacturers,
  set: (value) => {
    const filters = { ...props.filters, manufacturers: value };
    emit("update:filters", filters);
  },
});
</script>

<style scoped lang="scss">
h4,
label {
  font-family: var(--font-family);
}
h4 {
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
}
.c-list-row-bg {
  background-color: var(--list-row-bg);
}

.c-list-alternate-row-bg {
  background-color: var(--list-alternate-row-bg);
}
</style>

import { useErrorHandler } from "@/repositories/ErrorHandler";
import { documentApi } from "./DocumentApi";
import { useAuth } from "@cumulus/event-bus";
import { PrintDocumentRequest } from "./PrintDocumentRequest";
import { DocumentType } from "./DocumentType";
import { NotFoundError } from "@cumulus/http";

export function useDocumentService() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getAllPrinters = async () => {
    try {
      const authHeaders = await getAuthHeaders();
      return await documentApi.getPrinters(authHeaders);
    } catch (error) {
      handleError(error);
      throw error;
    }
  };

  const getDefaultPrinterByDocumentType = async (documentType: DocumentType) => {
    try {
      const authHeaders = await getAuthHeaders();
      return await documentApi.getDefaultPrinterByDocumentType(authHeaders, documentType);
    } catch (error) {
      if (!(error instanceof NotFoundError)) {
        console.error(error);
      }
    }
  };

  const printDocument = async (request: PrintDocumentRequest) => {
    try {
      const authHeaders = await getAuthHeaders();
      return await documentApi.printDocument(authHeaders, request);
    } catch (error) {
      handleError(error);
      throw error;
    }
  };

  const getPackingNoteUrl = async (packingNoteId: string): Promise<string> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await documentApi.getPackingNoteUrl(authHeaders, packingNoteId);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getPickingListUrl = async (pickingListId: string): Promise<string> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await documentApi.getPickingListUrl(authHeaders, pickingListId);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getPickingListUrl,
    getAllPrinters,
    getDefaultPrinterByDocumentType,
    printDocument,
    getPackingNoteUrl,
  };
}

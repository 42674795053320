<template>
  <PrimeDialog
    v-model:visible="visible"
    class="w-10"
    :header="t('inventory-count.some-lines-failed')"
    :modal="true"
    id="inventory-count-import-dialog"
    :breakpoints="{ '999px': '90vw', '640px': '95vw' }"
  >
    <DataTable
      :value="linesRef"
      :scrollable="true"
      scrollHeight="75vh"
      class="c-compact-datatable"
      :currentPageReportTemplate="
        t('common.current-page-template', { first: '{first}', last: '{last}', totalRecords: '{totalRecords}' })
      "
      :paginator="true"
      :rows="pageSize"
      :totalRecords="totalHits"
      ref="searchInventoryCountList"
      :stripedRows="true"
      selectionMode="single"
      data-testid="inventory-count-job-search-result"
      :rowsPerPageOptions="[50, 100]"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    >
      <Column field="productNumber" :header="t('inventory-count.product-number')" class="c-margin-auto w-3">
        <template #body="{ data, field, index }">
          <span class="p-input-icon-right w-full">
            <IconField>
              <InputIcon class="pi pi-spin pi-spinner" v-if="isCheckingProductForLine[data.id]"></InputIcon>
              <InputIcon
                class="pi pi-times"
                v-else-if="!isCheckingProductForLine[data.id] && hasProductErrors(data.errorReasons)"
              ></InputIcon>
              <InputIcon
                class="pi pi-check"
                v-else-if="!isCheckingProductForLine[data.id] && !hasProductErrors(data.errorReasons)"
              ></InputIcon>
              <InputText
                v-model="data[field]"
                class="inputfield w-full"
                :class="{
                  'p-invalid': hasProductErrors(data.errorReasons),
                }"
                v-debounce:400="() => validateProduct(data)"
                :data-testid="`c-product-number-input-${index}`"
              />
            </IconField>
          </span>
          <small class="p-error" v-if="hasProductErrors(data.errorReasons)" :data-testid="`c-product-error-${index}`">
            {{ getProductErrorMessages(data.errorReasons) }}
          </small>
        </template>
      </Column>
      <Column field="productName" :header="t('inventory-count.product-name')" class="c-margin-auto w-3">
        <template #body="{ data, field, index }">
          <span :data-testid="`c-count-line-product-name-${index}`">{{ data[field] }}</span>
        </template>
      </Column>
      <Column field="quantityInStock" :header="t('inventory-count.inventory')" class="c-margin-auto text-right">
        <template #body="{ data, field, index }">
          <span :data-testid="`c-count-line-inventory-${index}`">{{ data[field] }}</span>
        </template>
      </Column>
      <Column field="costPrice" :header="t('inventory-count.cost-price')" class="c-margin-auto text-right">
        <template #body="{ data, field, index }">
          <span :data-testid="`c-count-line-cost-price-${index}`">{{ n(data[field], "decimal") }}</span>
        </template>
      </Column>
      <Column field="newQuantity" :header="t('inventory-count.new-quantity')" class="c-margin-auto text-right w-2">
        <template #body="{ data, field, index }">
          <InputNumber
            :modelValue="data[field]"
            mode="decimal"
            :inputClass="{
              'c-row-input w-5rem': true,
              'p-invalid': data.errorReasons.includes(InventoryCountLineError.InvalidQuantity),
            }"
            class="w-full"
            @input="validateQuantity($event, data)"
            :data-testid="`c-new-quantity-input-${index}`"
          />
          <small
            class="p-error"
            v-if="data.errorReasons.includes(InventoryCountLineError.InvalidQuantity)"
            :data-testid="`c-new-quantity-error-${index}`"
          >
            {{ t("inventory-count.error-reason.invalid-quantity-description") }}
          </small>
        </template>
      </Column>
      <Column field="newCostPrice" :header="t('inventory-count.new-cost-price')" class="c-margin-auto text-right w-2">
        <template #body="{ data, field, index }">
          <InputNumber
            :modelValue="data[field]"
            :min-fraction-digits="2"
            :max-fraction-digits="2"
            :inputClass="{
              'c-row-input w-5rem': true,
              'p-invalid': data.errorReasons.includes(InventoryCountLineError.InvalidCostPrice),
            }"
            class="w-full"
            @input="validateCostPrice($event, data)"
            :data-testid="`c-new-cost-price-input-${index}`"
          />
          <small
            class="p-error"
            v-if="data.errorReasons.includes(InventoryCountLineError.InvalidCostPrice)"
            :data-testid="`c-new-cost-price-error-${index}`"
          >
            {{ t("inventory-count.error-reason.invalid-cost-price-description") }}
          </small>
        </template>
      </Column>
      <Column>
        <template #body="{ data, index }">
          <PrimeButton
            icon="pi pi-trash"
            class="p-button-rounded p-button-plain p-button-text"
            :data-testid="`inventory-count-line-delete-${index}`"
            @click="deleteLine(data)"
          />
        </template>
      </Column>

      <template #empty>
        <div class="c-table-empty">{{ t("inventory-count.empty-lines") }}</div>
      </template>
    </DataTable>

    <div class="flex justify-content-end">
      <PrimeButton
        class="c-circular-button mr-3"
        data-testid="btn-correction-cancel"
        @click="emit('update:visibleDialog', false)"
      >
        <i class="pi pi-times c-warning-button c-circular-icon"></i>
        <span class="px-3">{{ t("common.cancel") }}</span>
      </PrimeButton>
      <PrimeButton
        data-testid="btn-correction-save"
        class="c-circular-button mr-3"
        @click="onSave"
        :disabled="isSaving"
      >
        <ProgressSpinner v-if="isSaving" class="c-spinner" />
        <i v-else class="pi pi-check c-success-button c-circular-icon"></i>
        <span class="px-3">{{ t("common.save") }}</span>
      </PrimeButton>
    </div>
  </PrimeDialog>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { InventoryCountLine } from "../model/InventoryCountLine";
import { useI18n } from "vue-i18n";
import cloneDeep from "lodash.clonedeep";
import { InventoryCountLineError } from "../model/InventoryCountLineError";
import { InventoryCountLineStatus } from "../model/InventoryCountLineStatus";
import { NIL as emptyUuid } from "uuid";
import { useInventory } from "@/api/inventory/InventoryService";
import { InputNumberInputEvent } from "primevue/inputnumber";
import { InventoryCountJob } from "../model/InventoryCountJob";
import { useCumulusToast } from "@cumulus/toast";
import { useToast } from "primevue/usetoast";
import { useInventoryCountService } from "../api/InventoryCountService";
import { UpdateInventoryCountJobLinesRequest } from "../model/UpdateInventoryCountJobLinesRequest";

const props = defineProps<{
  inventoryCountJob: InventoryCountJob;
  failedLines: InventoryCountLine[];
  warehouseId: string;
  visibleDialog: boolean;
  pageSize: number;
  totalHits: number;
}>();

const emit = defineEmits<{
  (e: "update:visibleDialog", value: boolean): void;
  (e: "linesCorrected"): void;
}>();

const { t, n } = useI18n();
const { getWarehouseProduct, getProductByProductNumber } = useInventory();
const { updateInventoryCountJobLines } = useInventoryCountService();
const linesRef = ref<InventoryCountLine[]>(cloneDeep(props.failedLines));
const inventoryCountJobRef = ref<InventoryCountJob>(cloneDeep(props.inventoryCountJob));
const isCheckingProductForLine = ref<Record<string, boolean>>({});
const isSaving = ref<boolean>(false);
const toast = useCumulusToast(useToast());
const deletedLineIds = ref<string[]>([]);

watch([() => props.failedLines, () => props.inventoryCountJob], ([newFailedLines, newInventoryCountJob]) => {
  linesRef.value = cloneDeep(newFailedLines);
  inventoryCountJobRef.value = cloneDeep(newInventoryCountJob);
});

const visible = computed<boolean>({
  get: () => props.visibleDialog,
  set: (value) => emit("update:visibleDialog", value),
});

const hasProductErrors = (errorReasons: InventoryCountLineError[]) => {
  return (
    errorReasons.includes(InventoryCountLineError.ProductNotFound) ||
    errorReasons.includes(InventoryCountLineError.WarehouseProductNotFound) ||
    errorReasons.includes(InventoryCountLineError.DuplicatedProduct)
  );
};

const getProductErrorMessages = (errorReasons: InventoryCountLineError[]) => {
  return errorReasons.includes(InventoryCountLineError.ProductNotFound)
    ? t("inventory-count.error-reason.product-not-found-description")
    : errorReasons.includes(InventoryCountLineError.WarehouseProductNotFound)
    ? t("inventory-count.error-reason.warehouse-product-not-found-description")
    : errorReasons.includes(InventoryCountLineError.DuplicatedProduct)
    ? t("inventory-count.error-reason.duplicated-product-description")
    : "";
};

const validateWarehouseProduct = async (line: InventoryCountLine) => {
  const warehouseProduct = await getWarehouseProduct(props.warehouseId, line.productId);

  if (warehouseProduct) {
    line.status = InventoryCountLineStatus.Ready;
    line.errorReasons = line.errorReasons.filter((error) => error !== InventoryCountLineError.WarehouseProductNotFound);
  } else {
    line.status = InventoryCountLineStatus.Failed;
    if (!line.errorReasons.includes(InventoryCountLineError.WarehouseProductNotFound)) {
      line.errorReasons = [...line.errorReasons, InventoryCountLineError.WarehouseProductNotFound];
    }
  }
};

const checkForDuplicateProduct = (line: InventoryCountLine) => {
  const existingProductInJob = inventoryCountJobRef.value.lines.some(
    (l) => l.productNumber === line.productNumber && l.id !== line.id
  );

  const existingProductInFailedLines = linesRef.value.some(
    (l) => l.productNumber === line.productNumber && l.id !== line.id
  );

  const existingProduct = existingProductInJob || existingProductInFailedLines;

  if (existingProduct) {
    line.status = InventoryCountLineStatus.Failed;
    if (!line.errorReasons.includes(InventoryCountLineError.DuplicatedProduct)) {
      line.errorReasons = [...line.errorReasons, InventoryCountLineError.DuplicatedProduct];
    }
  } else {
    line.status = InventoryCountLineStatus.Ready;
    line.errorReasons = line.errorReasons.filter((error) => error !== InventoryCountLineError.DuplicatedProduct);
  }
};

const validateProduct = async (line: InventoryCountLine) => {
  try {
    isCheckingProductForLine.value[line.id] = true;

    const product = await getProductByProductNumber(line.productNumber);

    if (product !== null) {
      line.productName = product.name;
      line.productNumber = product.productNumber;
      line.productId = product.id;
      line.errorReasons = line.errorReasons.filter((error) => error !== InventoryCountLineError.ProductNotFound);

      checkForDuplicateProduct(line);

      await validateWarehouseProduct(line);
    } else {
      line.productId = emptyUuid;
      line.status = InventoryCountLineStatus.Failed;
      if (!line.errorReasons.includes(InventoryCountLineError.ProductNotFound)) {
        line.errorReasons = [...line.errorReasons, InventoryCountLineError.ProductNotFound];
      }
    }
  } finally {
    isCheckingProductForLine.value[line.id] = false;
  }
};

const validateCostPrice = (event: InputNumberInputEvent, line: InventoryCountLine) => {
  line.newCostPrice = event.value as number;
  if (line.newCostPrice < 0) {
    line.status = InventoryCountLineStatus.Failed;
    line.errorReasons = [...line.errorReasons, InventoryCountLineError.InvalidCostPrice];
  } else {
    line.status = InventoryCountLineStatus.Ready;
    line.errorReasons = line.errorReasons.filter((error) => error !== InventoryCountLineError.InvalidCostPrice);
  }
};

const validateQuantity = (event: InputNumberInputEvent, line: InventoryCountLine) => {
  line.newQuantity = event.value as number;
  if (line.newQuantity < 0) {
    line.status = InventoryCountLineStatus.Failed;
    line.errorReasons = [...line.errorReasons, InventoryCountLineError.InvalidQuantity];
  } else {
    line.status = InventoryCountLineStatus.Ready;
    line.errorReasons = line.errorReasons.filter((error) => error !== InventoryCountLineError.InvalidQuantity);
  }
};

const deleteLine = (line: InventoryCountLine) => {
  const index = linesRef.value.findIndex((l) => l.id === line.id);
  linesRef.value.splice(index, 1);
  inventoryCountJobRef.value.lines = inventoryCountJobRef.value.lines.filter((l) => l.id !== line.id);
  deletedLineIds.value.push(line.id);

  linesRef.value.forEach((line) => {
    checkForDuplicateProduct(line);
  });
};

const onSave = async () => {
  if (linesRef.value.some((line) => line.errorReasons.length > 0)) {
    toast.add({
      severity: "warn",
      summary: t("toast.validation-error.summary"),
      detail: t("toast.validation-error.detail"),
    });
    return;
  }

  try {
    isSaving.value = true;

    const request = new UpdateInventoryCountJobLinesRequest(
      inventoryCountJobRef.value.id,
      linesRef.value,
      deletedLineIds.value
    );

    await updateInventoryCountJobLines(request);

    toast.add({
      severity: "success",
      summary: t("inventory-count.changes-saved-summary"),
      detail: t("inventory-count.changes-saved-details"),
    });
  } finally {
    isSaving.value = false;
  }

  emit("linesCorrected");
  emit("update:visibleDialog", false);
};
</script>

<style scoped lang="scss">
.c-spinner {
  width: 22.83px;
}
</style>

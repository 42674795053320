<template>
  <label for="checkout-freight-method">{{ t("checkout.freight-method") }}</label>
  <Dropdown
    id="checkout-freight-method"
    :options="freightMethods"
    v-model="selectedFreightMethod"
    optionLabel="name"
    class="w-full"
    data-testid="checkout-freight-method"
    :inputProps="{ tabindex: 40 }"
  />
  <div v-if="isPickupPoint && pickupPoints != null" class="mt-2">
    <label for="checkout-freight-method">{{ "PickupPoints" }}</label>
    <Dropdown
      id="checkout-pickup-point"
      class="w-full"
      :options="pickupPoints"
      v-model="selectedPickupPoint"
      optionLabel="name"
      data-testid="checkout-pickup-point"
      :inputProps="{ tabindex: 40 }"
      @change="onPickupPointChanged"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { FreightMethod } from "@/repositories/freight-method/model/FreightMethod";
import { useFreightMethods } from "@/repositories/freight-method/FreightMethodService";
import { PickingFreight } from "@/repositories/picking-list/model/PickingFreight";
import { Bring } from "@/goods-out/bring-integration/Bring";
import { useBring } from "@/goods-out/bring-integration/BringService";
import { PickupPoint } from "@/goods-out/bring-integration/PickupPoint";
import { DropdownChangeEvent } from "primevue/dropdown";
import { Delivery } from "@/repositories/picking-list/model/Delivery";

const { getPickupPoints } = useBring();
const { t } = useI18n();
const { getFreightMethods } = useFreightMethods();
const freightMethods = ref<FreightMethod[]>([]);
const props = defineProps<{
  deliverTo: Delivery;
}>();

const pickingFreight = defineModel<PickingFreight>("pickingFreight", {
  required: true,
});

const bring = defineModel<Bring | null>("bring", {
  required: true,
});
const emit = defineEmits<{
  (event: "pickupPointChanged", value: PickupPoint): void;
}>();

const selectedPickupPoint = ref();
const onPickupPointChanged = (e: DropdownChangeEvent) => {
  emit("pickupPointChanged", e.value);
};
const pickupPoints = ref();
const selectedFreightMethod = computed<FreightMethod | null>({
  get: () => {
    return freightMethods.value.find((fm) => fm.id === pickingFreight.value.freightMethodId) ?? null;
  },
  set: async (fm: FreightMethod | null) => {
    if (fm) {
      pickingFreight.value.freightMethodId = fm.id;
      pickingFreight.value.freightMethodName = fm.name;
      pickingFreight.value.freightMethodTaxRate = fm.tax?.taxRate ?? 0;
      if (fm.bring === undefined) bring.value = null;
      else {
        bring.value = fm.bring;
        if (isPickupPoint.value) {
          pickupPoints.value = await getPickupPoints(
            "NO",
            props.deliverTo.address.postalCode,
            addressComputed.value.streetName ?? "",
            addressComputed.value.houseNumber ?? ""
          );
          selectedPickupPoint.value = pickupPoints.value[0];
        }
      }
    }
  },
});
const isPickupPoint = computed(() => {
  //5800 represents the Bring Shipping Service for Pickup Parcel
  return selectedFreightMethod.value?.bring?.bringShippingService.requestCode === "5800";
});

onMounted(async () => {
  freightMethods.value = await getFreightMethods();
  selectedFreightMethod.value = selectedFreightMethod.value ?? freightMethods.value[0];
  focus();
});

const focus = async () => {
  nextTick(async () => {
    const el = document.getElementById("checkout-freight-method");
    if (el != null) {
      const child = el.firstElementChild as HTMLElement;
      if (child != null) {
        child.focus();
      }
    }
  });
};

const addressComputed = computed(() => {
  //We can live with faulty address data here as faulty street address/number is just ignored by Bring
  //Bring returns an answer based on the postal code in these cases
  const regex = /^(.+?)\s+(\d+)([A-Z]?)$/;
  const match = props.deliverTo.address.addressLines[0].match(regex);
  const addressObject = {
    streetName: match ? match[1].trim() : null,
    houseNumber: match ? match[2] : null,
  };

  return addressObject;
});

defineExpose({ focus });
</script>

<template>
  <label for="inventory-quantity-reason">{{ t("inventory.reason") }}</label>
  <Dropdown
    id="inventory-quantity-reason"
    v-model="selectedReason"
    class="w-full"
    optionLabel="label"
    optionValue="value"
    :options="options"
    data-testid="inventory-quantity-reason"
    @show="isActive = true"
    @hide="isActive = false"
    @keydown.esc="isActive === true ? $event.stopPropagation() : ''"
  />
  <small class="p-error" v-if="validate.reason.$error" data-testid="inventory-reason-error">
    {{ validate.reason.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import { InventoryQuantityChangedReason } from "@/models/inventory/InventoryQuantityChangedReason";

const { t } = useI18n();
const options = ref<Record<string, string>[]>([]);

const props = defineProps<{
  reason: InventoryQuantityChangedReason;
}>();

const emit = defineEmits<{
  (e: "update:reason", value: InventoryQuantityChangedReason): void;
}>();

const isActive = ref(false);

const selectedReason = computed<InventoryQuantityChangedReason>({
  get: (): InventoryQuantityChangedReason => {
    return props.reason;
  },
  set: (value: InventoryQuantityChangedReason) => {
    emit("update:reason", value);
  },
});

onMounted(() => {
  options.value = Object.keys(InventoryQuantityChangedReason).map((key) => {
    return {
      label: t(`inventory.edit-inventory.reason.${key.toLowerCase()}`),
      value: key,
    };
  });
});

const rules = {
  reason: {
    required: required,
  },
};

const validate = useVuelidate(rules, props);
</script>

export const PackingNoteListColumn = {
  CreatedDate: "createdDate",
  PackingNoteNumber: "packingNoteNumber",
  OrderNumber: "orderNumber",
  FreightMethodName: "freightMethodName",
  CustomerNumber: "customerNumber",
  CustomerName: "customerName",
  QuantityOfOrderItems: "quantityOfOrderItems",
  SumTotalLines: "sumTotalLines",
  Pdf: "pdf",
} as const;

<template>
  <OpenPickingListsHeader
    @startPicking="onStartPicking"
    @showPickingList="showPickingList"
    @deletePickingList="confirmDelete"
    @onToggleFilterSidebar="onToggleFilterSidebar"
    :showSidebar="showSidebar"
    :pickingList="selectedPickingList"
  />
  <div class="c-picking-list" v-if="loadFailed">
    <div class="c-card">{{ t("picking-list.load-failed") }}</div>
  </div>
  <div class="c-ready-for-picking" v-else>
    <div class="flex">
      <div class="flex-auto">
        <div class="c-card">
          <Suspense>
            <OpenPickingLists
              v-model:selectedPickingList="selectedPickingList"
              @showPickingList="showPickingList"
              @onStartPicking="onStartPicking"
              @confirmDelete="confirmDelete"
              ref="pickingListSearchResult"
          /></Suspense>
        </div>
        <ConfirmDialog></ConfirmDialog>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ComponentPublicInstance, ref } from "vue";
import OpenPickingLists from "./OpenPickingLists.vue";
import OpenPickingListsHeader from "./OpenPickingListsHeader.vue";
import { useI18n } from "vue-i18n";
import { PickingList } from "@/repositories/picking-list/model/PickingList";
import router from "@/router/router";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { useConfirm } from "primevue/useconfirm";
import { useAuth } from "@cumulus/event-bus";
import { usePickingListStore } from "@/repositories/picking-list/PickingListStore";
import { PickingListState } from "@/repositories/picking-list/model/PickingListState";
import { NIL as emptyUuid } from "uuid";
import { storeToRefs } from "pinia";

const pickingListStore = usePickingListStore();
const { pickingLists } = storeToRefs(pickingListStore);

const { deletePickingList, startPicking } = pickingListStore;
const { getUser } = useAuth();
const loadFailed = ref(false);
const { t } = useI18n();

const toast = useCumulusToast(useToast());
const selectedPickingList = ref<PickingList>();
const showSidebar = ref(true);
const pickingListSearchResult = ref<ComponentPublicInstance<HTMLInputElement>>();

const onStartPicking = async () => {
  if (selectedPickingList.value === undefined) {
    return;
  }

  const user = await getUser();

  if (
    selectedPickingList.value.state === PickingListState.InPicking &&
    selectedPickingList.value.assignedEmployeeId == user.getEmployee().id
  ) {
    router.push({
      name: "picking-list",
      params: { pickingListId: selectedPickingList.value.id },
      query: { editMode: "true" },
    });
    return;
  }

  try {
    await startPicking(selectedPickingList.value.id);
  } catch {
    return;
  }

  router.push({
    name: "picking-list",
    params: { pickingListId: selectedPickingList.value.id },
    query: { editMode: "true" },
  });
};

const showPickingList = () => {
  selectedPickingList.value != null && selectedPickingList.value.id !== emptyUuid
    ? router.push({ name: "picking-list", params: { pickingListId: selectedPickingList.value.id } })
    : toast.add({
        severity: "warn",
        summary: "summary",
        detail: "detail",
      });
};

const confirm = useConfirm();

const confirmDelete = () => {
  if (selectedPickingList.value === undefined) {
    return;
  }

  confirm.require({
    header: t("common.confirm-delete"),
    message: t("picking-list.confirm-delete"),
    icon: "pi pi-exclamation-triangle",
    acceptClass: "c-dialog-success-button",
    rejectClass: "p-button-outlined",
    accept: onDeletePickingList,
    reject: clearSelectedRow,
  });
};

const clearSelectedRow = () => {
  selectedPickingList.value = undefined;
  focusSearchResult();
};

const onDeletePickingList = async () => {
  if (selectedPickingList.value === undefined) {
    return;
  }

  await deletePickingList(selectedPickingList.value?.id);
  clearSelectedRow();
};

const focusSearchResult = () => {
  if (pickingLists.value.length > 0) {
    const firstTableRow = pickingListSearchResult.value?.$el.querySelector("tbody tr:first-of-type");
    if (firstTableRow) {
      firstTableRow.focus();
    }
  }
};

const onToggleFilterSidebar = () => {
  showSidebar.value = !showSidebar.value;
};
</script>

<style scoped lang="scss"></style>

import { pickingListApi } from "./PickingListApi";
import { useErrorHandler } from "../ErrorHandler";
import { User, useAuth } from "@cumulus/event-bus";
import { PickingList } from "./model/PickingList";
import { ref } from "vue";
import { defineStore } from "pinia";
import { PickingListState } from "./model/PickingListState";
import { NIL as emptyUuid } from "uuid";
import { ConfirmPickingModel } from "./model/ConfirmPickingModel";
import { PickingListLine } from "./model/PickingListLine";
import { CreatePickingListsRequest } from "./model/CreatePickingListBody";

export const usePickingListStore = defineStore("pickingList", () => {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();
  const { getUser } = useAuth();

  const pickingLists = ref<PickingList[]>([]);
  const loading = ref(false);

  const pickingListById = (id: string): PickingList | null => {
    return pickingLists.value.find((pickingList: PickingList) => pickingList.id === id) ?? null;
  };

  const pickingListFindIndex = (id: string): number => {
    return pickingLists.value.findIndex((pickingList: PickingList) => pickingList.id === id);
  };

  const getOpenPickingLists = async (): Promise<void> => {
    try {
      loading.value = true;
      const authHeaders = await getAuthHeaders();
      pickingLists.value = await pickingListApi.getOpen(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    } finally {
      loading.value = false;
    }
  };

  const getPickingList = async (id: string): Promise<PickingList> => {
    try {
      loading.value = true;
      const authHeaders = await getAuthHeaders();
      const pickingList = await pickingListApi.get(authHeaders, id);
      const index = pickingListFindIndex(id);
      if (index !== -1) {
        pickingLists.value[index] = pickingList;
      } else {
        pickingLists.value.push(pickingList);
      }
      return pickingList;
    } catch (error) {
      await handleError(error);
      throw error;
    } finally {
      loading.value = false;
    }
  };

  const deletePickingList = async (id: string): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await pickingListApi.delete(authHeaders, id);
      const index = pickingListFindIndex(id);
      if (index !== -1) {
        pickingLists.value.splice(index, 1);
      }
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const startPicking = async (id: string): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await pickingListApi.start(authHeaders, id);
      const user = await getUser();
      setPickingListState(id, PickingListState.InPicking, user);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const parkPicking = async (id: string): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await pickingListApi.park(authHeaders, id);
      const user = await getUser();
      setPickingListState(id, PickingListState.Parked, user);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const cancelPicking = async (id: string): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await pickingListApi.cancel(authHeaders, id);
      const user = await getUser();
      setPickingListState(id, PickingListState.Open, user);
      const pickingList = pickingListById(id);
      if (pickingList !== null) {
        pickingList.lines.forEach((line) => (line.quantityPicked = null));
      }
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const confirmPicking = async (confirmPickingRequest: ConfirmPickingModel): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await pickingListApi.confirm(authHeaders, confirmPickingRequest);
      const index = pickingListFindIndex(confirmPickingRequest.pickingListId);
      if (index !== -1) {
        pickingLists.value.splice(index, 1);
      }
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const saveProgress = async (id: string, lines: PickingListLine[]): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await pickingListApi.saveProgress(authHeaders, id, lines);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const setPickingListState = (id: string, state: PickingListState, user: User): void => {
    //Only update store changed values (changed by server)
    const pickingList = pickingListById(id);
    if (pickingList !== null) {
      pickingList.state = state;

      if (state === PickingListState.InPicking) {
        pickingList.pickingStarted = new Date().toJSON();
      }

      if (state === PickingListState.Open) {
        pickingList.assignedEmployeeId = emptyUuid;
        pickingList.assignedEmployeeInitials = "";
      } else {
        const employee = user.getEmployee();
        pickingList.assignedEmployeeId = employee.id;
        pickingList.assignedEmployeeInitials = employee.initials;
      }
    }
  };

  const createPickingLists = async (request: CreatePickingListsRequest): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await pickingListApi.createPickingLists(authHeaders, request);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    pickingLists,
    loading,
    createPickingLists,
    pickingListById,
    getOpenPickingLists,
    getPickingList,
    deletePickingList,
    startPicking,
    parkPicking,
    cancelPicking,
    confirmPicking,
    saveProgress,
  };
});

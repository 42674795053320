{
  "print-picking-list": {
    "header": "Picking list",
    "no-result": "No picking list found",
    "created-date": "Date",
    "picking-list-number": "Picking list no.",
    "pdf": "PDF",
    "download-link-missing": "Failed to get picking list, please try again later",
    "printers": "Printers",
    "print-success": "Picking list sent to printer successfully",
    "print-success-detail": "Picking list is being printed to {printerName}",
    "printix": "Printix",
    "setup-printix-message": "Printix isnt setup yet. Go here to setup {0}"
  },
  "validations": {
    "required": "Value is required",
    "email": "Must be a valid e-mail address",
    "minLength": "Minimum length is {min}",
    "exists": "{property} already exists"
  },
  "placeholder": {
    "select": "Select {property}",
    "type": "Type {property}"
  },
  "common": {
    "select-printer": "Select a printer",
    "shipping-label-printer": "Shipping label printer",
    "packing-note-printer": "Packing note printer",
    "printer": "Printer",
    "print": "Print",
    "restart": "Restart",
    "start": "Start",
    "created": "Created",
    "add": "Add",
    "cancel": "Cancel",
    "ignore": "Ignore",
    "update": "Update",
    "create": "Create",
    "delete": "Delete",
    "close": "Close",
    "search": "Search",
    "date": "Date",
    "reload": "Reload",
    "column-chooser": "Column chooser",
    "reset": "Reset columns",
    "loading": "Loading...",
    "show-message": "Show message",
    "save": "Save",
    "error": "An error occurred",
    "current-page-template": "Showing {first} to {last} of {totalRecords}",
    "unsaved-changes-header": "Unsaved changes",
    "unsaved-changes-text": "You have unsaved changes. Save your changes or Discard for leaving without saving your changes. Choose Cancel to continue editing",
    "discard": "Discard",
    "save-tooltip": "No changes",
    "address": {
      "name": "Name",
      "address": "Address",
      "postal-code": "Postal code",
      "city": "Post office",
      "country": "Country"
    },
    "error-detail": {
      "error-occured": "An error occured",
      "unknown": "Unknown error",
      "server-error": "Server error",
      "not-found": "Resource not found",
      "bad-request": "Bad request"
    },
    "confirm-delete": "Delete confirmation",
    "filters": {
      "filter": "Filter",
      "filter-date-from": "From",
      "filter-date-to": "To",
      "filter-order-status": "Status",
      "filter-customer": "Customer",
      "filter-customer-group": "Customer Group",
      "filter-customer-number": "Customer number",
      "filter-product": "Product",
      "filter-product-number": "Product number",
      "filter-freight": "Freight",
      "show-filter": "Show filter",
      "hide-filter": "Hide filter",
      "clear-filter": "Clear filters"
    },
    "status": {
      "open": "Open",
      "partiallydelivered": "Partially delivered"
    }
  },
  "inventory": {
    "no-products-found": "No products found",
    "load-failed": "Could not load products from inventory, please try again later",
    "load-failed-inventoried-product": "Could not load inventoried product, please try again later",
    "productnumber": "Product number",
    "productname": "Product name",
    "productgroup": "Group level",
    "dimension": "Dimension (H*W*D {unit})",
    "weight": "Weight ({unit})",
    "ean": "EAN",
    "unit": "Unit",
    "warehouse": "Warehouse",
    "quantity-in-inventory": "Inventory",
    "quantity-in-picking-list": "In pick list",
    "quantity-available": "Available",
    "quantity-order-reserve": "Order reserve",
    "cost-price": "Cost price",
    "total-inventory-cost": "Total inventory cost",
    "main-supplier": "Main supplier",
    "main-supplier-productnumber": "Main supplier pnr",
    "advanced-search": "Advanced search",
    "reason": "Reason",
    "search": "Inventory search",
    "export": "Export",
    "file-formats": "File formats",
    "export-header": "Export inventory",
    "change-log": {
      "label": "Change log",
      "from-date": "From date",
      "to-date": "To date",
      "show-filter": "Show filter",
      "hide-filter": "Hide filter",
      "change-date": "Change date",
      "inventory-changes-log": "Inventory changes log",
      "no-inventory-changes-found": "No inventory changes found",
      "product-name": "Product name",
      "product-number": "Product number",
      "quantity": "Quantity",
      "changed-date": "Changed date",
      "change-number": "Change number",
      "batch-number": "Batch number",
      "total-change-value": "Total change value",
      "quantity-change": "Quantity change",
      "comment": "Comment",
      "reason": "Reason",
      "cost-price": "Cost price",
      "reasons": "Reasons",
      "clear-filters": "Clear filters"
    },
    "product-status": {
      "active": "Active",
      "inactive": "Inactive",
      "discontinued": "Discontinued"
    },
    "search-filters": {
      "header": "Product filter",
      "clear": "Clear filter",
      "status": "Status",
      "manufacturer": "Manufacturer"
    },
    "header": {
      "title": "Inventory",
      "detailed": "Details",
      "inventory": "Inventory",
      "cost": "Cost",
      "hide-filterbar": "Hide filterbar",
      "show-filterbar": "Show filterbar"
    },
    "context-menu": {
      "detailed-overview": "Detailed overview",
      "change-inventory": "Change inventory",
      "change-cost": "Change inventory cost",
      "copy": "Copy"
    },
    "product-modal": {
      "overview-header": "{product} - Detailed overview"
    },
    "batch": {
      "header": "Batch overview",
      "date-received": "Date received",
      "purchase-number": "Purchase number",
      "batch-number": "Batch number",
      "quantity-received": "Quantity received",
      "quantity-available": "Quantity available",
      "quantity-in-inventory": "Quantity in inventory",
      "quantity-allocated": "Quantity allocated",
      "quantity-allocated-for-pick": "Quantity allocated for pick",
      "received-cost": "Received cost",
      "supplier-name": "Supplier name",
      "supplier-product-number": "Supplier product number",
      "no-batches-found": "No batches found for this product"
    },
    "edit-inventory": {
      "header": "{inventoryProduct} - Change inventory",
      "existing-inventory": "Current quantity",
      "new-inventory": "New quantity",
      "changed-value": "Change",
      "comment": "Comment",
      "print": "Print",
      "add-success": "Inventory is updated!",
      "update-failed": "Failed to update inventory, inventory has been changed since you started",
      "reason": {
        "defective": "Defect, internal damage",
        "errorinpicklist": "Error detected during picking",
        "inventorycount": "Inventory count",
        "returnfromcustomer": "Return from customer"
      }
    },
    "edit-cost": {
      "header": "{inventoryProduct} - Change inventory cost",
      "inventory-cost": "Inventory value now",
      "unit-cost": "Unit cost now",
      "inventory-cost-up-down": "Inventory value up/down",
      "new-unit-cost": "New unit cost",
      "add-success-inventory": "Inventory cost is updated!",
      "add-success-unit": "Unit cost is updated!",
      "no-inventory-cost-change": "No change in inventory value!",
      "no-unit-cost-change": "No change in unit cost!",
      "changed": {
        "new-inventory-value": "New inventory value",
        "changed-plus-minus": "Changed +/-"
      },
      "cost": {
        "totalInventoryCost": "Total inventory cost",
        "unitCost": "Unit cost"
      },
      "reason": {
        "costadjustment": "Cost adjustment with own funds",
        "wrongprice": "Wrong price on receipt of goods",
        "priceprotection": "Price protection from supplier"
      }
    }
  },
  "warehouse": {
    "header": "Warehouse",
    "name": "Warehouse name",
    "country": "Country",
    "address": "Address",
    "warehouse-address": "Warehouse address",
    "currencies": "Currencies",
    "state": "Active",
    "company-name": "Company name",
    "shortname": "Short name",
    "post-office": "Post office",
    "currency": "Currency",
    "client": "Client",
    "add": "Add warehouse",
    "no-warehouses-found": "No warehouses found",
    "loading": "Fetching warehouses..."
  },
  "order-ready-for-picking": {
    "header": "Ready for picking",
    "no-orders-ready-for-picking-found": "No orders ready for picking",
    "load-failed": "Could not load orders ready for picking, please try again later",
    "shipping-date": "Shipping date",
    "orderNumber": "Order number",
    "freightMethod": "Freight method",
    "customerNumber": "Customer no.",
    "name": "Customer name",
    "orderReference": "Order ref.",
    "quantity-of-order-items": "Order lines",
    "sumTotalLines": "Value ready to picking",
    "context-menu": {
      "create-picking-lists": "Create picking lists",
      "select-all": "Select all",
      "cancel": "Cancel"
    },
    "confirm-create-header": "Send to picking",
    "confirm-create-message": "Do you want to send all selected orders to picking?",
    "confirm-accept": "Send to picking",
    "confirm-reject": "Cancel",
    "created-picking-list": {
      "add-success": "Created picking list(s)"
    },
    "websocket-error": "Live updates are unavailable",
    "websocket-error-details": "Could not establish a connection to the server"
  },
  "picking-lines": { 
    "header": "Picking lines"
  },
  "picking-list": {
    "header": "Picking lists",
    "no-result": "No open picking lists found",
    "load-failed": "Could not load picking lists, please try again later",
    "header-view-picking-list": "Picking list",
    "shipping-date": "Shipping date",
    "picking-list-number": "Picking list no.",
    "state": "State",
    "employee-initials": "Employee",
    "order-number": "Order number",
    "freight-method-name": "Freight method",
    "customer-number": "Customer no.",
    "customer-name": "Customer name",
    "order-reference": "Order ref.",
    "quantity-of-order-items": "Order lines",
    "sum-of-total-ex-vat": "Picking list value",
    "position-number": "Pos no.",
    "product-number": "Product no.",
    "supplier-product-number": "Supplier product no.",
    "gtin": "GTIN",
    "product-name": "Product name",
    "quantity-for-picking": "In order",
    "quantity-picked": "Picked",
    "quantity-remaining": "Remaning",
    "select-picking-list": "Select a picking list",
    "context-menu": {
      "start-picking": "Start picking",
      "continue-picking": "Take over",
      "show-picking-list": "Show picking list",
      "delete-picking-list": "Delete picking list"
    },
    "confirm-delete": "Do you want to delete the picking list?",
    "freight": "Freight",
    "complete-delivery": "Complete delivery",
    "contact": "Contact",
    "deliver-to": "Deliver to:",
    "payment": "Payment",
    "order-type": "Order type",
    "message": "Message",
    "park": "Park",
    "confirm": "Confirm",
    "cancel": "Cancel picking",
    "confirm-cancel": "Do you want to cancel picking and return all items?",
    "already-in-picking": "Picking list is not in picking state",
    "in-picking-by": "Already in picking by {initials}",
    "park-picking-list-header": "Park picking list",
    "park-picking-list-message": "Do you want to park the picking list?",
    "park-empty-picking-list-header": "The picking list has no picked lines",
    "park-empty-picking-list-message": "No products have been picked, the picking list will go back to open. Do you want to continue?",
    "states": {
      "open": "Open",
      "parked": "Parked",
      "parked-by": "Parked by {initials}",
      "done": "Done",
      "inpicking": "In picking",
      "inpicking-by": "In picking by {initials}"
    },
    "picking-started": "Picking started",
    "detail-picking-started": "Started: ",
    "confirm-all-detail": "Do you want to confirm picking all open lines?",
    "confirm-all": "Confirm all",
    "confirmed-by": "Picked by {initials}",
    "validation": {
      "package-height-missing": "Height missing for package number {packageNumber}",
      "package-width-missing": "Width missing for package number {packageNumber}",
      "package-length-missing": "Length missing for package number {packageNumber}",
      "package-weight-missing": "Weight missing for package number {packageNumber}"
    }
  },
  "checkout": {
    "header": "Confirm checkout",
    "pickinglist-number": "Picking list:",
    "order-number": "Order:",
    "customer": "Customer:",
    "delivery-to": "Deliver to:",
    "phone-number": "Phone:",
    "email": "E-mail:",
    "freight-method": "Freight method",
    "consignment-number": "Consignment number",
    "freight-reference": "Freight reference",
    "freight-price": "Shipping cost",
    "freight-weight": "Weight",
    "freight-packages": "Packages",
    "confirm": "Confirm",
    "weight": "Weight (kg)",
    "length": "Length (cm)",
    "width": "Width (cm)",
    "height": "Height (cm)",
    "content": "Content",
    "parcel": "Parcel",
    "totalWeight": "Total weight (kg)",
    "totalVolume": "Total volume (ccm)",
    "shippingCost": "Freight cost",
    "shippingPrice": "Freight price",
    "estimateShippingPrice": "Calculate shipping price",
    "error": "An error occurred",
    "errorGettingWarehouses": "Could not get warehouses, please try again later",
    "not-available": "Not available",
    "error-getting-shipping-guide": "Failed to get a succesfull response from Bring. Please try again later",
    "add-parcel": "Add parcel"
  },
  "toast": {
    "add-success": {
      "summary": "Warehouse is added!"
    },
    "update-success": {
      "summary": "Warehouse is updated!"
    },
    "delete-success": {
      "summary": "Warehouse is deleted!"
    },
    "error": {
      "summary": "An error occurred",
      "unknown-error": "An unknown error occured, please try again later",
      "server-error": "Server error, please try again later",
      "active-error": "The warehouse is active, set the warehouse to inactive and try again",
      "load-error": "Couldn't load warehouses"
    },
    "validation-error": {
      "summary": "Validation failed",
      "detail": "Correct all red fields and try again"
    },
    "no-change": {
      "summary": "No change",
      "detail": "No change in quantity"
    }
  },
  "delete": {
    "active-error": "Set to inactive before deleting",
    "confirm-message": "Are you sure you want to delete this warehouse?"
  }
}

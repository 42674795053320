<template>
  <div class="grid">
    <div class="col-12 xl:col-4 lg:col-12">
      <div class="c-card c-customer c-card-background">
        <div v-if="!isMobile" class="flex justify-content-start gap-2 align-items-center">
          <PickingListNumber :pickinglistNumber="pickingList.pickingListNumber" data-testid="picking-list-number" />
          <Tag class="mr-2" data-testid="state-tag" :value="stateComputed" :severity="(severityComputed as any)"></Tag>
          <span
            v-if="pickingList?.state === PickingListState.InPicking || pickingList?.state === PickingListState.Parked"
            >{{ pickingStartedComputed }}</span
          >
        </div>
        <div class="grid md:p-2 mt-2 c-background-light">
          <div class="col-12 md:col-6">
            <b
              >{{ t("picking-list.customer-number") }} :
              <span data-testid="customer-number">{{ pickingList?.customerNumber }}</span></b
            >
            <div class="c-invoice-address">
              <div data-testid="customer-name">{{ pickingList?.customerName }}</div>
              <div>{{ pickingList?.invoiceAddress.addressLines.join(", ") }}</div>
              <div>{{ pickingList?.invoiceAddress.postalCode }} {{ pickingList?.invoiceAddress.city }}</div>
            </div>
          </div>

          <div class="col-12 md:col-6">
            <b>{{ t("picking-list.deliver-to") }}</b>
            <div>{{ pickingList?.customerName }}</div>
            <div>{{ pickingList?.delivery.address.addressLines.join(", ") }}</div>
            <div>{{ pickingList?.delivery.address.postalCode }} {{ pickingList?.delivery.address.city }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 xl:col-4 md:col-6">
      <div class="c-card c-order-props">
        <div class="grid mt-2">
          <div class="col-12 xl:col-6">
            <b>{{ t("picking-list.order-reference") }} : </b>
            <span data-testid="order-reference">{{ pickingList?.orderReference }}</span>
            <div class="mt-4">
              <b>{{ t("picking-list.contact") }} : </b>
              <span>{{ pickingList?.contactName }}</span>
            </div>
          </div>
          <div class="col-12 xl:col-6">
            <b>{{ t("picking-list.freight") }} : </b>
            <span data-testid="freight-method">{{ pickingList?.freightMethodName }}</span>
            <div class="field-checkbox mt-4">
              <Checkbox
                inputId="complete-delivery"
                :model-value="pickingList?.completeDelivery"
                :binary="true"
                :disabled="true"
              ></Checkbox>
              <label for="complete-delivery">{{ t("picking-list.complete-delivery") }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 xl:col-4 md:col-6">
      <div class="c-card c-order-comment pt-4">
        <div class="grid">
          <div class="col-12 xl:col-6">
            <b>{{ t("picking-list.payment") }}: </b>
            <span data-testid="payment-term">{{ pickingList?.paymentTermName }}</span>
            <div class="mt-4">
              <b>{{ t("picking-list.order-type") }}: </b>
            </div>
            <span></span>
          </div>
          <div class="col-12 xl:col-6">
            <b>{{ t("picking-list.message") }}: </b>
            <span>{{ pickingList?.orderComment }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import PickingListNumber from "./checkout-dialog/components/PickingListNumber.vue";
import { PickingList } from "@/repositories/picking-list/model/PickingList";
import { PickingListState } from "@/repositories/picking-list/model/PickingListState";

const props = defineProps<{
  pickingList: PickingList;
  isMobile: boolean;
  stateComputed?: string;
  severityComputed?: unknown;
}>();

const { t, d } = useI18n();

const pickingStartedComputed = computed<string>(() =>
  props.pickingList.pickingStarted
    ? t("picking-list.detail-picking-started") + d(props.pickingList?.pickingStarted, "long")
    : ""
);
</script>

<style scoped lang="scss">
.c-customer,
.c-order-props,
.c-order-comment {
  min-height: 100%;
}
.c-invoice-address {
  @media (min-width: 767px) {
    min-height: 3rem;
    background-image: linear-gradient(to bottom, #000 10%, rgba(255, 255, 255, 0) 0%);
    background-position: right;
    background-size: 2px 12px;
    background-repeat: repeat-y;
  }
}

.c-background-light {
  background-color: var(--primary-color-text);
}

@media (max-width: 992px) {
  .c-card {
    margin: 0;
    border-radius: 0;
  }
}
</style>

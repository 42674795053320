<template>
  <Teleport to="#c-mobile-toolbar" v-if="isMobile || isTablet">
    <PrimeButton
      :disabled="pickingList === undefined || !startPickingAllowed"
      class="p-button-text"
      @click="emit('startPicking')"
      data-testid="btn-start-picking"
    >
      <i class="pi pi-caret-right"></i>
      <span class="c-mobile-toolbar-text">{{ startPickingButtonLabel }}</span>
    </PrimeButton>
  </Teleport>
  <Teleport to="#c-more-options-menu" v-if="isMobile || isTablet">
    <PrimeButton
      :disabled="pickingList === undefined || pickingList.state !== PickingListState.Open"
      class="p-button-text"
      @click="emit('deletePickingList')"
      data-testid="btn-delete-picking-list-toolbar"
    >
      <i class="pi pi-trash"></i>
      <span class="c-mobile-toolbar-text">{{ t("picking-list.context-menu.delete-picking-list") }}</span>
    </PrimeButton>

    <PrimeButton
      :disabled="pickingList === undefined"
      class="p-button-text"
      @click="emit('showPickingList')"
      data-testid="btn-show-picking-list"
    >
      <i class="pi pi-sign-in"></i>
      <span class="c-mobile-toolbar-text">{{ t("picking-list.context-menu.show-picking-list") }}</span>
    </PrimeButton>
  </Teleport>
  <div class="c-toolbar-wrapper">
    <div class="c-toolbar">
      <div class="c-header-icon-container">
        <div v-tooltip.bottom="savePickingTooltip">
          <PrimeButton
            :disabled="pickingList === undefined || !startPickingAllowed"
            class="c-circular-button mr-3"
            @click="emit('startPicking')"
            data-testid="btn-start-picking"
          >
            <i class="pi pi-caret-right c-success-button c-circular-icon"></i>
            <span class="px-3">{{ startPickingButtonLabel }}</span>
          </PrimeButton>
        </div>
        <PrimeButton
          :disabled="pickingList === undefined || pickingList?.state !== PickingListState.Open"
          class="c-circular-button mr-3"
          @click="emit('deletePickingList')"
          data-testid="btn-delete-picking-list-toolbar"
        >
          <i class="pi pi-trash c-delete-button c-circular-icon"></i>
          <span class="px-3">{{ t("picking-list.context-menu.delete-picking-list") }}</span>
        </PrimeButton>

        <PrimeButton
          :disabled="pickingList === undefined"
          class="c-circular-button mr-3"
          @click="emit('showPickingList')"
          data-testid="btn-show-picking-list"
        >
          <i class="pi pi-sign-in c-default-button c-circular-icon button"></i>
          <span class="px-3">{{ t("picking-list.context-menu.show-picking-list") }}</span>
        </PrimeButton>
      </div>
      <div class="c-header-filter-container c-header-icon-container">
        <PrimeButton class="c-circular-button" @click="emit('onToggleFilterSidebar')">
          <i class="pi pi-filter c-default-button c-circular-icon"></i>
          <span class="px-3">{{
            showSidebar ? t("common.filters.hide-filter") : t("common.filters.show-filter")
          }}</span>
        </PrimeButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PickingList } from "@/repositories/picking-list/model/PickingList";
import { PickingListState } from "@/repositories/picking-list/model/PickingListState";
import { useAuth, User } from "@cumulus/event-bus";
import { computed, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useMobile } from "@/MobileService";

const { t } = useI18n();
const { isMobile, isTablet } = useMobile();

const user = ref<User>(new User());
const { getUser } = useAuth();
onMounted(async () => {
  user.value = await getUser();
});

const props = defineProps<{
  pickingList: PickingList | undefined;
  showSidebar: boolean;
}>();

const emit = defineEmits<{
  (e: "startPicking"): void;
  (e: "showPickingList"): void;
  (e: "deletePickingList"): void;
  (e: "onToggleFilterSidebar"): void;
}>();

const startPickingAllowed = computed<boolean>(() => {
  if (!props.pickingList || props.pickingList === undefined) {
    return false;
  }
  return (
    props.pickingList.state === PickingListState.Open ||
    (props.pickingList.state === PickingListState.InPicking &&
      props.pickingList.assignedEmployeeId === user.value.getEmployee().id) ||
    props.pickingList.state === PickingListState.Parked
  );
});

const startPickingButtonLabel = computed<string>(() => {
  if (!props.pickingList || props.pickingList === undefined) {
    return t("picking-list.context-menu.start-picking");
  }

  if (props.pickingList.state === PickingListState.Parked) {
    return t("picking-list.context-menu.continue-picking");
  } else {
    return t("picking-list.context-menu.start-picking");
  }
});

const savePickingTooltip = computed(() => {
  return !props.pickingList
    ? { value: t("picking-list.select-picking-list"), showDelay: 100, hideDelay: 300 }
    : { value: "" };
});
</script>
<style scoped lang="scss">
.c-circular-button:disabled {
  opacity: 0.6;
}
</style>
